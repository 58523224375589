import { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './App.css';
import { useQuery } from '@tanstack/react-query';
import { config } from './constants';
import { useParams } from 'react-router-dom';
import Upgrade from './Upgrade';
import useGaTracker from './useGaTracker';

type Step = {
  id: number;
  image: string;
  text: string;
  hotspot_x: number;
  hotspot_y: number;
};

type Demo = {
  title: string;
  width: number;
  height: number;
  remove_branding: boolean;
};

type Response = {
  demo: Demo;
  steps: Step[];
};

function DemoWidget() {
  const demoWidget = useRef<HTMLDivElement>(null);
  const image = useRef<HTMLImageElement>(null);
  const tippy = useRef<any>(null);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const { token } = useParams();
  useGaTracker();

  const { data, isError } = useQuery({
    queryKey: ['stepListData'],
    queryFn: (): Promise<Response> =>
      fetch(`${config.API_URL}api/demos/${token}/steps`).then((res) =>
        res.json()
      ),
  });

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (demoWidget.current?.requestFullscreen) {
        demoWidget.current?.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const goNext = () => {
    tippy.current._tippy.hide();
    setTimeout(() => {
      if (data) {
        // handle normal step progression
        if (currentStep !== data.steps.length - 1) {
          setCurrentStep((prev) => prev + 1);
        } else {
          // handle clicking hotspot on last step
          setCurrentStep(0);
        }
        setTimeout(() => {
          tippy.current?._tippy.show();
        }, 1000);
      }
    }, 200);
  };

  window.mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        //eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  let browserTabClassNames: string = '';
  let touchBannerClassNames: string = '';
  let imageContainerClassNames: string = '';

  if (window.mobileAndTabletCheck() === true) {
    browserTabClassNames =
      'hidden w-full h-10 bg-gray-100 justify-between items-center px-3';
    touchBannerClassNames = 'flex justify-between w-full p-0 bg-red-600';
    imageContainerClassNames =
      'w-full border-l-[1px] border-r-[1px] border-t-[1px] rounded-tr-lg rounded-tl-lg overflow-hidden';
  } else {
    browserTabClassNames =
      'hidden w-full h-10 bg-gray-100 sm:flex justify-between items-center px-3';
    touchBannerClassNames =
      'sm:hidden flex justify-between w-full p-0 bg-red-600';
    imageContainerClassNames =
      'w-full border-l-[1px] border-r-[1px] border-t-[1px] sm:border rounded-tr-lg rounded-tl-lg sm:rounded-lg overflow-hidden';
  }

  const hasAuthToken = localStorage.getItem('demopondAuthToken');

  if (isError) return <div>An error has occurred.</div>;

  if (!data)
    return (
      <div className="flex justify-center items-center gap-2 h-screen">
        Initializing Demo...
        <div role="status">
          <svg
            aria-hidden="true"
            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin  fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  return (
    <div ref={demoWidget} className="w-full relative">
      <div className={imageContainerClassNames}>
        <div className={browserTabClassNames}>
          <div className="flex justify-between gap-2">
            <div className="h-3 w-3 rounded-full bg-red-400"></div>
            <div className="h-3 w-3 rounded-full bg-yellow-400"></div>
            <div className="h-3 w-3 rounded-full bg-green-400"></div>
          </div>
          <div className="w-1/2">
            <div className="h-6 border rounded-lg bg-gray-200 text-xs flex justify-between items-center px-2">
              <div></div>
              <div className="flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="gray"
                  className="w-3 h-3 mt-0.5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>{data.demo.title}</span>
              </div>
              <Tippy
                content="Replay"
                interactive={true}
                theme="tomato"
                touch={false}
                disabled={currentStep !== data.steps.length - 1}
                visible
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-3 h-3 cursor-pointer"
                  onClick={() => setCurrentStep(0)}
                >
                  <path
                    fillRule="evenodd"
                    d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                    clipRule="evenodd"
                  />
                </svg>
              </Tippy>
            </div>
          </div>
          <div onClick={toggleFullScreen} className="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
              />
            </svg>
          </div>
        </div>
        <div className="relative">
          <img
            className="w-full"
            ref={image}
            src={data.steps[currentStep].image}
            onLoad={() => {
              setImageWidth(image.current?.width || 0);
              setImageHeight(image.current?.height || 0);
              if (image.current) {
                const observer = new ResizeObserver((entries) => {
                  setImageWidth(entries[0].contentRect.width);
                  setImageHeight(entries[0].contentRect.height);
                });
                observer.observe(image.current);
              }
              // Pre-render next image if possible
              if (currentStep !== data.steps.length - 1) {
                const img = new Image();
                img.src = data.steps[currentStep + 1]?.image;
              }
            }}
            alt=""
          />
          {imageWidth &&
          imageHeight &&
          data.steps[currentStep].hotspot_x &&
          data.steps[currentStep].hotspot_x ? (
            <Tippy
              content={data.steps[currentStep].text}
              interactive={true}
              theme="tomato"
              touch={false}
              disabled={
                window.mobileAndTabletCheck() || !data.steps[currentStep].text
              }
              trigger="manual"
              showOnCreate
              hideOnClick={false}
              ref={tippy}
            >
              {/* add left/top padding to adjust hotspot position for mobile (mobile has smaller hotspot) */}
              {/* add right padding to align content to popup */}
              <div
                className="pl-1 pt-1 pr-1.5 sm:pl-0 sm:pt-0 h-10 w-10 cursor-pointer absolute flex justify-center items-center inset-0 transition-transform transform-gpu duration-700 ease-in-out delay-100"
                style={{
                  transform: `translate(${
                    imageWidth *
                      (data.steps[currentStep].hotspot_x / data.demo.width) -
                    20
                  }px, ${
                    imageHeight *
                      (data.steps[currentStep].hotspot_y / data.demo.height) -
                    23
                  }px)`,
                }}
                onClick={goNext}
              >
                {/* add left/top padding to center inner most solid circle */}
                <div className="relative sm:pl-1.5 sm:pt-1.5 pl-0.5 pt-0.5 flex justify-center items-center">
                  <div className="h-5 w-5 sm:h-8 sm:w-8 rounded-full bg-red-600 absolute inset-0 animate-ping"></div>
                  <div className="h-4 w-4 sm:h-5 sm:w-5 rounded-full bg-red-600 relative inset-0"></div>
                </div>
              </div>
            </Tippy>
          ) : null}
          {!data.demo.remove_branding && (
            <Upgrade
              text="Remove Demopond branding by upgrading to Demopond Pro"
              subscription_status={!hasAuthToken}
            >
              <div className="text-[8px] bottom-2 z-[90] absolute right-2 sm:bottom-4 sm:right-4 sm:text-sm  border px-2 py-1 sm:px-2.5 sm:py-1.5 rounded-md shadow-sm cursor-pointer hover:shadow-md bg-white transition-bottom ease-in-out duration-500">
                <a
                  href="https://app.demopond.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Made with Demopond
                </a>
              </div>
            </Upgrade>
          )}
        </div>
      </div>

      <div
        className={`${touchBannerClassNames} absolute -bottom-10 left-0 right-0 min-h-[41px] border-l-[1px] border-r-[1px] border-b-[1px] rounded-bl-lg rounded-br-lg px-2 z-[100]`}
      >
        <div className="flex items-center mx-auto">
          <p className="flex items-center text-xs font-normal text-slate-50">
            <span>{data.steps[currentStep].text}</span>
          </p>
        </div>
        <div className="flex items-center">
          {currentStep !== data.steps.length - 1 ? (
            <button
              type="button"
              className="flex-shrink-0 inline-flex justify-center items-center hover:bg-red-700 hover:text-gray-900 rounded-lg text-sm p-1.5"
              onClick={goNext}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          ) : (
            <button
              type="button"
              className="flex-shrink-0 inline-flex justify-center items-center hover:bg-red-700 hover:text-gray-900 rounded-lg text-sm p-1.5"
              onClick={() => setCurrentStep(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  fillRule="evenodd"
                  d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DemoWidget;
