import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { config } from './constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Notification from './Notification';
import { useState } from 'react';
import useGaTracker from './useGaTracker';

type Step = {
  id: number;
  image: string;
  text: string;
  hotspot_x: number;
  hotspot_y: number;
};

type Demo = {
  title: string;
  width: number;
  height: number;
};

type Response = {
  demo: Demo;
  steps: Step[];
};

export default function DemoPreview() {
  const navigate = useNavigate();
  const { token } = useParams();
  useGaTracker();
  const [show, setShow] = useState<boolean>(false);

  const authToken = localStorage.getItem('demopondAuthToken');

  const { isLoading, error, data } = useQuery({
    queryKey: ['stepListData'],
    queryFn: (): Promise<Response> =>
      fetch(`${config.API_URL}api/demos/${token}/steps`).then((res) =>
        res.json()
      ),
  });

  if (!data) return null;

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>An error has occurred.</div>;

  const snippet = `<div style="position: relative; padding-bottom: calc(${(data.demo.height / data.demo.width) * 100}% + 41px); height: 0; width: 100%"><iframe src="${window.location.origin}/${token}" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;" title="Interactive demo sourced by Demopond"></iframe></div>`

  return (
    <>
      <div className="flex justify-between bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div>
          {authToken && (
            <button
              type="button"
              className="rounded-md bg-white my-4 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => navigate(`/`)}
            >
              Back
            </button>
          )}
        </div>
        <div>
          {!authToken && (
            <button
              type="button"
              className="rounded-md bg-blue-600 my-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => navigate(`/`)}
            >
              Create demo
            </button>
          )}
          {authToken && (
            <>
              <button
                type="button"
                className="rounded-md bg-white mr-3 my-4 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => navigate(`/demos/${token}/edit`)}
              >
                Edit
              </button>
              <CopyToClipboard
                text={snippet}
                onCopy={() => {
                  setShow(true);
                  setTimeout(() => {
                    setShow(false);
                  }, 3000);
                }}
              >
                <button
                  type="button"
                  className="rounded-md bg-blue-600 my-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get embed code
                </button>
              </CopyToClipboard>
            </>
          )}
        </div>
      </div>
      <div className="my-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
        <div className="mx-auto max-w-7xl">
          {/* Content goes here */}
          <div
            style={{
              position: 'relative',
              paddingBottom: `calc(${
                (data.demo.height / data.demo.width) * 100
              }% + 41px)`,
              height: '0',
              width: '100%',
            }}
          >
            <iframe
              src={`${window.location.origin}/${token}`}
              frameBorder="0"
              loading="lazy"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                colorScheme: 'light',
              }}
              title="Free Screen Recorder - Video Messaging Software for PC | Vidshot"
            ></iframe>
          </div>
        </div>
      </div>
      <Notification
        title="Successfully copied!"
        description="Embed code copied to clipboard."
        show={show}
        closeNotif={() => setShow(false)}
      />
    </>
  );
}
