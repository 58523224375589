import { config } from './constants';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, Fragment } from 'react';
import useGaTracker from './useGaTracker';
import { Transition, Popover } from '@headlessui/react';
import DeleteDemoModal from './DeleteDemoModal';
import Notification from './Notification';

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
// */
// const files = [
//   {
//     title: 'IMG_4985.HEIC',
//     size: '3.9 MB',
//     source:
//       'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
//   },
//   // More files...
// ];

type TokenInfo = {
  extensionId: string;
  jwt: string;
};

export default function DemoList() {
  const [hasExtensionInstalled, setHasExtensionInstalled] = useState(false);
  const [selectedDemoToken, setSelectedDemoToken] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useGaTracker();

  const { isLoading, error, data } = useQuery({
    queryKey: ['demoListData'],
    queryFn: () =>
      fetch(`${config.API_URL}api/demos/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('demopondAuthToken')}`,
        },
      }).then((res) => res.json()),
  });

  const {
    mutate,
    isLoading: isDeleting,
    isError,
  } = useMutation({
    mutationFn: async () => {
      return fetch(`${config.API_URL}api/demos/${selectedDemoToken}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('demopondAuthToken')}`,
        },
      }).then(async (res) => {
        if (!res.ok) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
      });
    },
    cacheTime: 0,
    onSuccess: () => {
      setIsModalOpen(false);
      setSelectedDemoToken('');
      queryClient.invalidateQueries({ queryKey: ['demoListData'] });
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    },
    onError: (err: Error) => {
      setIsModalOpen(false);
      setSelectedDemoToken('');
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    },
  });

  const sendTokenToChromeExtension = ({ extensionId, jwt }: TokenInfo) => {
    chrome.runtime?.sendMessage(extensionId, { jwt }, (response) => {
      if (!response.success) {
        console.log('error sending message', response);
        return response;
      }
      console.log('Sucesss ::: ', response.message);
      setHasExtensionInstalled(true);
    });
  };

  useEffect(() => {
    const authToken = localStorage.getItem('demopondAuthToken');
    if (!authToken) {
      return;
    }
    sendTokenToChromeExtension({
      extensionId: 'ocinaikcohbnnnpmnphgolagbhgiogfp',
      jwt: authToken,
    });
  }, []);

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>An error has occurred.</div>;

  if (data.length === 0 && !hasExtensionInstalled) {
    return (
      <div
        className="flex justify-center items-center text-center"
        style={{ height: 'calc(100vh - 180px)' }}
      >
        <div className="flex flex-col gap-4">
          <p>
            Your demos will appear here. Install our chrome extension to start
            recording!
          </p>
          <a href="https://chrome.google.com/webstore/detail/demopond/ocinaikcohbnnnpmnphgolagbhgiogfp">
            <button
              type="button"
              className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Install Chrome Extension
            </button>
          </a>
        </div>
      </div>
    );
  }

  if (data.length === 0 && hasExtensionInstalled) {
    return (
      <div
        className="flex justify-center items-center text-center"
        style={{ height: 'calc(100vh - 180px)' }}
      >
        <div className="flex flex-col gap-4">
          <p>Follow these steps to record your first demo!</p>
          <ol className="text-left">
            <li>1. Go to the URL you want to capture</li>
            <li>2. Open the extension</li>
            <li>3. Click on Record demo</li>
            <li>4. Click through the feature you would like to present</li>
            <li>5. Click on Stop recording to stop</li>
          </ol>
        </div>
      </div>
    );
  }

  return (
    <>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {data.map((demo: any) => (
          <li key={demo.token} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img
                src={demo.thumbnail}
                alt=""
                className="pointer-events-none object-cover group-hover:opacity-75"
              />
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
                onClick={() => navigate(`/demos/${demo.token}`)}
              >
                <span className="sr-only">View details for {demo.title}</span>
              </button>
            </div>
            <div className="flex justify-between items-center">
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                {demo.title}
              </p>
              <Popover className="relative">
                <Popover.Button
                  className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 w-full outline-none"
                  onClick={() => setSelectedDemoToken(demo.token)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 mt-3 hover:bg-gray-50 rounded-md"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                    />
                  </svg>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 -bottom-15">
                    <div className="max-w-md flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      <div className="relative rounded-lg p-4 hover:bg-gray-50">
                        <button
                          className="font-semibold text-gray-900"
                          onClick={() => setIsModalOpen(true)}
                        >
                          Delete
                          <span className="absolute inset-0" />
                        </button>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
            </div>

            {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">
            {file.size}
          </p> */}
          </li>
        ))}
      </ul>
      <DeleteDemoModal
        open={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        submitForm={mutate}
        isLoading={isDeleting}
      />
      <Notification
        title={
          isError ? 'Failed to delete demo.' : 'Successfully deleted demo!'
        }
        description={
          isError
            ? "Demo doesn't exist or you do not have permission to delete it."
            : 'You now have a cleaner dashboard.'
        }
        show={show}
        closeNotif={() => setShow(false)}
        isError={isError}
      />
    </>
  );
}
