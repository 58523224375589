import { useEffect } from "react";
import ReactGA from "react-ga4";

const useInitializeGaTracker = () => {

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            ReactGA.initialize('G-YMV519G9ZY');
          }
    }, [])
    
};

export default useInitializeGaTracker;