import Tippy from '@tippyjs/react';
import { useMutation } from '@tanstack/react-query';
import { config } from './constants';

export default function Upgrade({ children, text, subscription_status }: any) {
  const { mutate, isLoading, isError, error } = useMutation({
    mutationFn: async () => {
      return fetch(`${config.API_URL}api/stripe/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('demopondAuthToken')}`,
        },
        body: JSON.stringify({ plan: 'monthly' }),
      }).then(async (res: any) => {
        const response = await res.json();
        const { url } = response;
        if (window.top) {
          window.top.location.href = url;
        } else {
          window.location.href = url;
        }
      });
    },
    cacheTime: 0,
  });

  const content = (
    <div className="px-3 mt-2.5 flex flex-col w-[219px]">
      <p className="text-white">{text}</p>
      <div>
        <button
          type="button"
          className="rounded-md bg-blue-600 mr-3 my-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          disabled={isLoading}
          onClick={() => mutate()}
        >
          Upgrade
        </button>
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <a
            href="https://www.demopond.com/#pricing"
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
        </button>
      </div>
    </div>
  );

  return isError && error instanceof Error ? (
    <Tippy theme="upgrade" interactive disabled={!!subscription_status}>
      <div>An error occurred: {error.message}</div>
    </Tippy>
  ) : (
    <Tippy
      content={content}
      theme="upgrade"
      interactive
      disabled={!!subscription_status}
    >
      {children}
    </Tippy>
  );
}
