import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import ReactGA from "react-ga4";

interface JwtPayload {
        user_id: string;
    }

const useGaTracker = () => {
    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            const authToken = localStorage.getItem('demopondAuthToken')
            if (authToken) {
                const payload: JwtPayload = jwtDecode(authToken)
                const userId = payload.user_id
                ReactGA.set({ userId })
            }
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
        }
    }, []);
}
export default useGaTracker;