import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { config } from './constants';
import Upgrade from './Upgrade';
import { useOutletContext } from 'react-router-dom';
import InviteUserModal from './InviteUserModal';
import { useState } from 'react';
import Notification from './Notification';
import useGaTracker from './useGaTracker';

type ContextType = {
  firstName: string;
  lastName: string;
  subscription_status: string | null;
};

export default function UserList() {
  const { subscription_status }: ContextType = useOutletContext();
  const queryClient = useQueryClient();
  useGaTracker();

  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);

  const { data } = useQuery({
    queryKey: ['userListData'],
    queryFn: () =>
      fetch(`${config.API_URL}api/users/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('demopondAuthToken')}`,
        },
      }).then((res) => res.json()),
  });

  const { mutate, isLoading, isError, error } = useMutation({
    mutationFn: async () => {
      return fetch(`${config.API_URL}api/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('demopondAuthToken')}`,
        },
        body: JSON.stringify({ email }),
      }).then(async (res) => {
        if (!res.ok) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }

        return res.json();
      });
    },
    cacheTime: 0,
    onSuccess: () => {
      setOpen(false);
      setEmail('');
      queryClient.invalidateQueries({ queryKey: ['userListData'] });
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    },
    onError: (err: Error) => {
      setOpen(false);
      setEmail('');
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    },
  });

  const submitForm = () => {
    setIsEmailError(false);
    const isEmailValid =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      );
    if (isEmailValid) {
      mutate();
    } else {
      setIsEmailError(true);
    }
  };

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {/* Users */}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {/* A list of all the users in your account including their name, title,
            email and role. */}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Upgrade
            text={
              'Upgrade to Demopond Pro to invite other users into your workspace.'
            }
            subscription_status={subscription_status}
          >
            <span className="py-3">
              <button
                type="button"
                className="rounded-md bg-blue-600 my-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-blue-300"
                disabled={!subscription_status}
                onClick={() => setOpen(true)}
              >
                Invite user
              </button>
            </span>
          </Upgrade>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {data &&
                  data.map(
                    (user: {
                      first_name: string;
                      last_name: string;
                      email: string;
                      status: string;
                    }) => (
                      <tr key={user.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {user.first_name + ' ' + user.last_name}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          Member
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.status}
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <InviteUserModal
        open={open}
        closeModal={() => setOpen(false)}
        email={email}
        isEmailError={isEmailError}
        setEmail={(email: string) => setEmail(email)}
        submitForm={submitForm}
        isLoading={isLoading}
      />
      <Notification
        title={
          isError ? 'Failed to invite user.' : 'Successfully invited user!'
        }
        description={
          isError ? error.message : 'They can now login using their email.'
        }
        show={show}
        closeNotif={() => setShow(false)}
        isError={isError}
      />
    </div>
  );
}
