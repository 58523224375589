import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './App.css';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { config } from './constants';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import Modal from './Modal';
import Notification from './Notification';
import useGaTracker from './useGaTracker';

type Step = {
  id: number;
  image: string;
  text: string;
  hotspot_x: number;
  hotspot_y: number;
};

type Demo = {
  title: string;
  width: number;
  height: number;
};

type Response = {
  demo: Demo;
  steps: Step[];
};

export default function DemoEdit() {
  const navigate = useNavigate();
  const { token } = useParams();
  const queryClient = useQueryClient();
  useGaTracker();

  const demoWidget = useRef<HTMLDivElement>(null);
  const image = useRef<HTMLImageElement>(null);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  const { isLoading, error, data } = useQuery({
    queryKey: ['stepListData'],
    queryFn: (): Promise<Response> =>
      fetch(`${config.API_URL}api/demos/${token}/steps`).then((res) =>
        res.json()
      ),
  });

  const { mutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (text: string) => {
      return fetch(
        `${config.API_URL}api/demos/${token}/steps/${data?.steps[currentStep].id}/`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem(
              'demopondAuthToken'
            )}`,
          },
          body: JSON.stringify({ text }),
        }
      );
    },
    onSuccess: () => {
      setOpen(false);
      setText('');
      queryClient.invalidateQueries({ queryKey: ['stepListData'] });
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    },
  });

  if (!data) return null;

  if (isLoading) return <div>Loading...</div>;

  if (error) return <div>An error has occurred.</div>;

  return (
    <>
      <div className="flex justify-between bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div>
          <button
            type="button"
            className="rounded-md bg-white my-4 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => navigate(`/`)}
          >
            Back
          </button>
        </div>
        <div>
          <button
            type="button"
            className="rounded-md bg-white my-4 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => navigate(`/demos/${token}`)}
          >
            Preview
          </button>
        </div>
      </div>
      <div className="flex justify-center pt-16">
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            onClick={() => setCurrentStep(currentStep - 1)}
            disabled={currentStep === 0}
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-8 w-8" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            onClick={() => setCurrentStep(currentStep + 1)}
            disabled={currentStep === data.steps.length - 1}
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </span>
      </div>
      <div className="my-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
        <div className="mx-auto max-w-7xl">
          {/* Content goes here */}

          <div
            ref={demoWidget}
            className="w-full border rounded-lg overflow-hidden"
          >
            <div className="hidden w-full h-10 bg-gray-100 sm:flex justify-between items-center px-3">
              <div className="flex justify-between gap-2">
                <div className="h-3 w-3 rounded-full bg-red-400"></div>
                <div className="h-3 w-3 rounded-full bg-yellow-400"></div>
                <div className="h-3 w-3 rounded-full bg-green-400"></div>
              </div>
              <div className="w-1/2">
                <div className="h-6 border rounded-lg bg-gray-200 text-xs flex justify-between items-center px-2">
                  <div></div>
                  <div className="flex gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="gray"
                      className="w-3 h-3 mt-0.5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{data.demo.title}</span>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-3 h-3 cursor-pointer"
                    onClick={() => setCurrentStep(0)}
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
              {/* this is a blank div used for flex items to work correctly, it's where the toggle fullscreen button used to go*/}
              <div className="cursor-pointer"></div>
            </div>
            <div className="relative">
              <img
                ref={image}
                src={data.steps[currentStep].image}
                onLoad={() => {
                  setImageWidth(image.current?.width || 0);
                  setImageHeight(image.current?.height || 0);

                  if (image.current) {
                    const observer = new ResizeObserver((entries) => {
                      setImageWidth(entries[0].contentRect.width);
                      setImageHeight(entries[0].contentRect.height);
                    });
                    observer.observe(image.current);
                  }
                }}
                alt=""
              />
              {imageWidth &&
              imageHeight &&
              data.steps[currentStep].hotspot_x &&
              data.steps[currentStep].hotspot_x ? (
                <Tippy
                  content={data.steps[currentStep].text}
                  interactive={true}
                  theme="tomato"
                  touch={false}
                  disabled={!data.steps[currentStep].text}
                >
                  {/* add left/top padding to adjust hotspot position for mobile (mobile has smaller hotspot) */}
                  {/* add right padding to align content to popup */}
                  <div
                    className="pl-1 pt-1 pr-1.5 sm:pl-0 sm:pt-0 h-10 w-10 cursor-pointer absolute flex justify-center items-center inset-0 transition-transform transform-gpu duration-700 ease-in-out delay-100"
                    style={{
                      transform: `translate(${
                        imageWidth *
                          (data.steps[currentStep].hotspot_x /
                            data.demo.width) -
                        20
                      }px, ${
                        imageHeight *
                          (data.steps[currentStep].hotspot_y /
                            data.demo.height) -
                        23
                      }px)`,
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {/* add left/top padding to center inner most solid circle */}
                    <div className="relative sm:pl-1.5 sm:pt-1.5 pl-0.5 pt-0.5 flex justify-center items-center">
                      <div className="h-5 w-5 sm:h-8 sm:w-8 rounded-full bg-red-600 absolute inset-0 animate-ping"></div>
                      <div className="h-4 w-4 sm:h-5 sm:w-5 rounded-full bg-red-600 relative inset-0"></div>
                    </div>
                  </div>
                </Tippy>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        closeModal={() => setOpen(false)}
        text={text}
        setText={(text: string) => setText(text)}
        submitForm={() => mutate(text)}
        isUpdating={isUpdating}
      />
      <Notification
        title="Successfully saved!"
        description="Your hotspot text has been updated."
        show={show}
        closeNotif={() => setShow(false)}
      />
    </>
  );
}
