import Upgrade from './Upgrade';
import { useOutletContext } from 'react-router-dom';
import { Link } from 'react-router-dom';
import useGaTracker from './useGaTracker';

type ContextType = {
  firstName: string;
  lastName: string;
  subscription_status: string | null;
};

export default function Customize() {
  const { subscription_status }: ContextType = useOutletContext();
  useGaTracker();

  return (
    <Upgrade
      text="Upgrade to Demopond Pro to add a custom domain to your demos."
      subscription_status={subscription_status}
    >
      <span className="py-3">
        <Link
          to="#"
          onClick={(e) => {
            window.location.href =
              "mailto:yourfriends@demopond.com?subject=I'm interested in adding a custom domain.";
            e.preventDefault();
          }}
        >
          <button
            type="button"
            className="rounded-md bg-blue-600 my-4 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-blue-300"
            disabled={!subscription_status}
          >
            Add custom domain
          </button>
        </Link>
      </span>
    </Upgrade>
  );
}
